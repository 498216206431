@import "styles/variables";

.card {
  width: 100%;
  height: 60px;
  border-radius: 12px;
  background-color: $inputBg;
  backdrop-filter: blur(3px);
  border: 2px solid $borderColor;
  padding: 12px 16px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  display: flex;

  &:hover {
    background: $elBg;
  }

  &Selected {
    border: 2px solid $yellow;
    background: $elBg;
  }
}