@import "styles/variables";

.container {
  color: $silver;

  .selectedTitle {
    color: $yellow;
  }

  .circle {
    border-radius: 60px;
    border: 2px solid $borderColor;
    background: #122133;
  }

  .secondBlueCircle {
    background: $royalBlue;
  }
}