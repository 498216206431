
.textGradient {
  position: absolute;
  top: 20px;
  height: 38px;
  width: 100%;
  background: linear-gradient(180deg, rgba(18, 33, 51, 0.00) 0%, rgba(18, 33, 51, 0.80) 32.81%, #122133 100%);
}

.textGradientInCard {
  background: linear-gradient(180deg, rgba(23, 37, 54, 0.00) 0%, rgba(23, 37, 54, 0.80) 42.71%, #172536 100%);
}

.signWrapper {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  border: 2px solid var(--Borders, #27394B);
  background: var(--Muted-Violet, #374A82);
}