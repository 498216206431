$main-bg: rgb(247, 247, 247);

$primary: #122133;
$white: #FFFFFF;
$silver: #E5E5E5;
$gray: #4C556F;
$yellow: #FFD700;
$electricBlue: #7DF9FF;
$borderColor: #27394B;
$regularPurple: #9B7BD0;
$disabled: #58A1A5;
$secondary: #5A8C8D;
$green: #6B8E23;
$red: #CD5C5C;
$blue: #4169E1;
$orange: #F2AC3C;
$violet: #8E4BF6;
$royalBlue: #3F5497;
$mutedGold: #746C20;
$subtitleColor: #A5A5A5;

$elBg: #172536;
$inputBg: rgba(23, 37, 54, 0.02);

/*---Layout ---*/
$grid-padding:              28px;

/* fonts*/
$fMontserrat: 'Montserrat', sans-serif;
$fRoboto: 'Roboto', sans-serif;
$fPapyrus: 'Papyrus', sans-serif;

