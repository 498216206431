@import "../../../styles/variables";

.container {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 24px;
  padding: 0 5.33%;

  .rightContainer {
    display: inline-flex;
    align-items: flex-start;
    gap: 8px;
    position: relative;

    .userType {
      position: absolute;
      top: -8px;
      color: $silver;
      text-align: center;
      left: 5px;
      font-family: $fMontserrat;
      font-size: 6px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }

    .headerBtn {
      width: 30px;
      height: 30px;
      display: inline-block;
    }
  }
}
