.flicking-pagination-bullet {
  width: 14px;
  height: 14px;
  background-color: #27394B;
  margin: 0 3px
}

.flicking-pagination-bullet-active {
  width: 16px;
  height: 16px;
  background-color: #7DF9FF;
}

.flicking-viewport {
  overflow: initial;
}

.flicking-pagination {
  bottom: -48px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.flicking-camera {
  gap: 16px;
  padding: 0 calc(5.33% - 7.1422px);
}