@import "src/styles/variables";

.verificationContainer {
  width: 100%;
  margin-top: 113px;
  justify-content: center;
}

.character {
  width: 60px;
  height: 60px;
  background-color: $inputBg;
  border-radius: 12px;
  border: 2px solid $borderColor;
  color: #4C556F;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-basis: unset;
  line-height: normal;
  display: flex;

  font-family: $fRoboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;

  &Selected {
    color:  $silver;
    font-family: $fRoboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
  }
}

.characterError {
  border: 2px solid $regularPurple;
}

.character .vi__character {
  flex-grow: 0;
  flex-basis: unset;
}

.error {
  color: $regularPurple;
  font-family: $fMontserrat;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  margin-top: 16px;
}