@import 'src/styles/variables';

.container {
  display: flex;
  padding: 18px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 2px solid $borderColor;
  background: $elBg;

  color: $silver;

  font-family: $fRoboto;
  font-size: 16px;
  font-weight: 400;

  .customBtn {
    text-align: center;
    font-family: $fMontserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
}