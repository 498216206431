@import "src/styles/variables";

.content {
  color: $silver;
  font-family: $fMontserrat;
  font-style: normal;
  line-height: normal;
  text-align: left;

  .subtitle {
    font-family: $fRoboto;
    font-size: 18px;
    font-weight: 400;
    margin-top: 16px;
  }

  .section {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;

    h3 {
      font-size: 20px;
    }

    .iconsContainer {
      justify-content: center;
      align-items: center;
      gap: 12px 1.79%;
      flex-wrap: wrap;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
    }
  }
}
