@import "src/styles/variables";

.container {
  height: 204px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .elementsContainer {
    position: absolute;
    display: flex;
    width: 45.835%;

    .element {
      width: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;

      p {
        text-align: center;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        width: 100%;
      }

      img {
        margin: auto auto;
      }
    }
  }
}

@media screen and (max-width: 360px) {
 .container {
   width: 300px;
   margin: 0 auto;

   .elementsContainer {
     top: 51px;

     .element {
       p {
         margin-bottom: 11px;
       }
     }
   }
 }
}

@media screen and (min-width: 361px) {
  .container {
    width: 340px;
    margin: 0 auto;

    .elementsContainer {
      top: 48px;

      .element {
        p {
          margin-bottom: 12px;
        }
      }
    }
  }
}