.circle {
  border: 1px solid var(--Borders, #27394B);
  background: var(--Primary, #122133);
  width: 204px;
  height: 204px;
  border-radius: 200px;
  padding: 32px;
  position: absolute;
  top: 0;
  z-index: -1;
}

.progressBarWrapper {
  height: 6px;
  border-radius: 6px;
}