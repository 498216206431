.swiper {
    width: 100%;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
}

.swiper-slide img {
    display: block;
    width: 100%;
}

.swiper-cube .swiper-cube-shadow {
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: 0;
}

.swiper-cube .swiper-cube-shadow:before {
    content: '';
    background: rgba(18, 33, 51, 0.01);

    box-shadow: 0px 22px 15px 0px rgba(0, 0, 0, 0.30);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    filter: blur(100px);
}

.swiper-pagination-horizontal {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -18px;
}

.swiper-pagination-horizontal .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background: #5A8C8D;
    border: 1px solid  #27394B;
}

.swiper-pagination-horizontal .swiper-pagination-bullet-active {
    width: 12px;
    height: 12px;
    background: #7DF9FF;
    border: 1px solid #27394B;
}