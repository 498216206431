@import "src/styles/variables";

.contentContainer {
  text-align: left;
  margin-top: 28px;
  color: $silver;
  font-family: $fMontserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  .coffeeCupContainer {
    margin-top: 24px;
    margin-bottom: 40px;

    .itemsContainer {
      height: 85px;

      .itemContainer {
        width: 29.85%;
        height: 85px;
        margin: 0 auto;
        background: $elBg;
      }
    }
  }

  .compatibilityContainer {
    background:  rgba(255, 255, 255, 0.02);
    padding: 40px 5.33%;

    .infoContainer {
      width: 100px;
      margin: 16px auto 32px;

      .labelsContainer {
        width: 120%;
        bottom: -10px;

        p {
          color: #FFF;
          text-align: center;
          font-size: 8px;
          text-transform: uppercase;
        }
      }
    }
  }

  .luckContainer {
    background:  rgba(255, 255, 255, 0.02);
    padding: 40px 5.33%;
  }

  .dailyTipContainer {
    padding: 0 5.33%;
    margin: 40px 0;
    overflow: hidden;
  }

  .sliderContainer {
    margin: 40px 0 0;
    padding: 0 0 40px;
    overflow: hidden;

    h2 {
      padding: 0 5.33%;
    }

    div > div {
      gap: 16px;
    }

    .dailyTipItemContainer {
      width: 72%;
      border-radius: 12px;
      border: 1px solid $borderColor;
      background: $elBg;
      backdrop-filter: blur(20px);
      padding: 18px;
      height: 261px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        font-size: 18px;
        margin-top: 6px;
        margin-bottom: 8px;
      }
    }
  }
}

.personalizedCompatibilityContainer {
  padding: 20px 5.33%;
  margin: 40px 0;

  .personalizedContainer {
    width: 100%;
    border-radius: 20px;
    border: 2px solid var(--Royal-Blue, #3F5497);
    background: var(--El-Background, #172536);
    backdrop-filter: blur(2px);
    display: flex;
    padding: 20px 18px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;

    .contentWrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;

      h3 {
        font-size: 18px;
      }

      p {
        color: $white;
        text-align: center;
        font-family: $fRoboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .unlockBtnContainer {
      display: flex;
      justify-content: flex-end;
      align-self: flex-end;

      button {
        color: $electricBlue;
        font-family: $fMontserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        svg {
          margin-left: 6px;
        }
      }
    }
  }
}

.biorhythmsContainer {
  padding: 0 5.33%;
  text-align: left;
  color: $silver;
  font-family: $fMontserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 40px;
}

.biorhythmsItemContainer {
  border-radius: 12px;
  border: 2px solid $borderColor;
  background: $elBg;
  padding: 10px;
  width: 23.28%;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6px;

  .title {
    font-size: 8px;
    text-transform: uppercase;
    cursor: pointer;
  }

  .pieContainer {
    width: 50px;
    height: 50px;
    border: 2px solid var(--Borders, #27394B);
    border-radius: 25px;
    background-color: #122133;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      position: absolute;
      font-size: 14px;
      font-weight: 500;
    }

    .container {
      width: 44px;
      height: 44px;
    }
  }
}


.radarContainer {
  padding: 8px 0 32px;
  display: flex;
  justify-content: center;
}

.tarotReadingContainer {
  padding: 0 5.33% 80px;

  .cardsContainer {
    height: 167px;
    gap: 3.582%;
  }
}

.firstBlock {
  padding: 0 5.33%;
}

.recharts-wrapper {
  width: 280px !important;
  overflow: inherit !important;
}

.luckyItemContainer {
  width: 50px;
  height: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #E5E5E5;
  font-family: $fPapyrus;
  font-size: 30px;
  font-weight: 400;
  white-space: nowrap;
}

button {
  color: $electricBlue;
  font-family: $fMontserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    margin-left: 6px;
  }
}

.readMoreContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 32px;

  button {
    color: $electricBlue;
    font-family: $fMontserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    svg {
      margin-left: 6px;
    }
  }
}

.infographicContainer {
  border-radius: 12px;
  border: 2px solid $borderColor;
  background: $elBg;
  padding: 10px 0;
  width: 33.3%;
  align-items: center;
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;
    color: $electricBlue;
    cursor: pointer;
  }

  .pieContainer {
    width: 50px;
    height: 50px;
    border: 2px solid $borderColor;
    border-radius: 25px;
    background-color: #122133;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      position: absolute;
      font-size: 14px;
      font-weight: 500;
    }

    .container {
      width: 44px;
      height: 44px;
    }
  }
}

.containerPaddingH16 {
  padding:  0 16px !important;
}