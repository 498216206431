@import "src/styles/variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*[position='fixed'] {
  display: flex !important;
  justify-content: center !important;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.container {

  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;

  .itemContainer {
    width: max-content;

    border-radius: 12px;
    border: 2px solid var(--Borders, #27394B);
    background: var(--El-Background, #172536);
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;

    .pieContainer {
      width: 50px;
      height: 50px;
      border: 2px solid var(--Borders, #27394B);
      border-radius: 25px;
      background-color: #122133;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .innerContainer {
        width: 44px;
        height: 44px;
      }

      .text {
        position: absolute;
        font-size: 14px;
        font-weight: 500;
        color: var(--Silver, #E5E5E5);
        font-family: $fMontserrat;
        font-style: normal;
        line-height: normal;
      }
    }
  }

    p {
      color: $silver;
      font-family: $fRoboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  }
}