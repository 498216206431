@import "styles/variables";

.infographicContainer {
  border-radius: 12px;
  border: 2px solid $borderColor;
  background: $elBg;
  padding: 10px;
  width: 33.3%;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .title {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;
    color: $electricBlue;
    cursor: pointer;
  }

  .pieContainer {
    width: 50px;
    height: 50px;
    border: 2px solid $borderColor;
    border-radius: 25px;
    background-color: #122133;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      position: absolute;
      font-size: 14px;
      font-weight: 500;
    }

    .container {
      width: 44px;
      height: 44px;
    }
  }
}