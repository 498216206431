@import "styles/variables";

.itemsContainer {
  gap: 12px 12px;

  .itemContainer {
    width: 29.85%;
    height: 85px;
    margin: 0 auto;
    background: $elBg;
  }

  .selectedItem {
    border: 2px solid var(--Yellow, #FFD700);
  }
}
