@import "src/styles/variables";

.btnContainer {
  display: flex;
  width: 49.6567%;
  padding: 10px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: $silver;
  text-align: center;
  font-family: $fMontserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.isActive {
  border-radius: 10px;
  background: $electricBlue;
  color: #122133;
}