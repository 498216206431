@import "src/styles/variables";

.card {
  display: flex;
  padding: 24px 18px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 2px solid $borderColor;
  background: $primary;
  font-style: normal;
  line-height: normal;

  .dateContainer {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    p {
      color: $silver;
      font-family: $fMontserrat;
      font-size: 13px;
      font-weight: 600;
    }
  }

  .text {
    color: $white;
    font-family: $fRoboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }
}