@import 'styles/variables';

.cardHeader {
  width: 100%;

  .colorLabel {
    display: inline-flex;
    padding: 4px 6px;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid  $borderColor;
  }
}

.textContainer {
  text-align: justify;
  overflow: hidden;

  .textGradient {
    position: absolute;
    top: 20px;
    height: 38px;
    width: 100%;
    background: linear-gradient(180deg, rgba(18, 33, 51, 0.00) 0%, rgba(18, 33, 51, 0.80) 32.81%, #122133 100%);
  }

  .textGradientInCard {
    background: linear-gradient(180deg, rgba(23, 37, 54, 0.00) 0%, rgba(23, 37, 54, 0.80) 42.71%, #172536 100%);
  }
}

.overviewBtnWrapper {
  margin: 8px auto 0;
}